import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../layouts/layout"
import SearchFormMobile from "../components/search/search-form-mobile"
import SearchResult from "../components/search/result"
import { useFlexSearch } from "react-use-flexsearch"
import { flattenPageSearchItems } from "../utils/helpers"
import * as striptags from "striptags"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
 
const SearchPage  = ({ data }) => {
	let results = []
	const { index, store } = data.search
  const breakpoints = useBreakpoint()
  const isMobile = breakpoints.sm

	let keys = ``
	if (typeof window !== "undefined") {
		const { search } = window.location
		keys  = new URLSearchParams(search).get('keys')
	}

	results = useFlexSearch(keys, index, store)

	if (!keys) {
		results = Object.keys(store).map(id => store[id])
	}
	const hasResults = results.length > 0

	return (
		<Layout>
			<div className="bg-white relative mx-auto max-w-xl lg:max-w-7xl px-4 pb-16 pt-32 sm:px-6 lg:px-8 lg:pt-64 lg:pb-28">
        {isMobile && <SearchFormMobile keys={keys} />}
        <div className="divide-y-2 divide-gray-200">
          <div>
            {!keys && <h2 className="text-3xl font-bold tracking-tight text-sky-900 sm:text-4xl">
              Search results
            </h2>}  
            {keys && <h2 className="text-3xl font-bold tracking-tight text-sky-900 sm:text-4xl">
              Search results for <em>{keys}</em>
            </h2>}
          </div>
	        <div className="mt-6 lg:pt-16 grid gap-16 pt-10 lg:grid-cols-3 lg:gap-x-10 lg:gap-y-12">
	        	{hasResults && results.map(item => (
              <SearchResult keys={keys} item={item} />    
            ))}
            {!hasResults && <div>No results</div>}
	        </div>  
        </div>
      </div>
    </Layout>
	)
}

export const Head = ({ location, data }) => {
  const canonicalUrl = `${process.env.GATSBY_SITE_URL}search/`
  return (
    <>
      <meta name="robots" content="noindex"/> 
      <title>Search | DEC Housing</title>
      <link rel="canonical" href={canonicalUrl} />
    </>
  )
}

export default SearchPage

export const searchPageQuery = graphql`
  query SearchPageQuery {
    search: localSearchSite {
      index
      store
    }
  }
`