import * as React from "react"
import { Link } from "gatsby"
import truncate from "lodash/truncate" 

export default function SearchResult({ item, keys }) {
  return (
  <div key={item.title}>
    <h3>
      <Link to={item.path} className="block">
        {item.title}
      </Link>
    </h3>
    <div className="textarea text-gray-600" dangerouslySetInnerHTML={{ __html: item.summary }}></div>
  </div>
  )
}