import React, { useState, useEffect, useRef } from "react"
import { navigate } from "gatsby"
import { XMarkIcon } from '@heroicons/react/24/outline'

export default function SearchFormMobile({ keys }) {
  const searchMobileInputRef = useRef()
  const [searchQuery, setSearchQuery] = useState(keys)
  
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
        event.preventDefault()
        if (event.target.matches('button[type="reset"]')) {
          handleResetButton(event)
        } else {
          handleSubmit()
        }
      }
    }
    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    };
  }, [])

  const handleInputChange = (e) => {
    setSearchQuery(e.target.value)
  }

  const handleResetButton = (e) => {
    e.stopPropagation()
    setSearchQuery(``)
    searchMobileInputRef.current.value = ``
    searchMobileInputRef.current.focus()
  }

  const handleSubmit = () => {
    const searchQuery = searchMobileInputRef.current.value
    navigate(`/search?keys=${searchQuery}`)
  }

 	return (
		<form onSubmit={handleSubmit} action="/search" className="relative" method="get">
      <input
        autoFocus
        ref={searchMobileInputRef}
        autoComplete="off"
        type="text"
        name="keys"
        id="search-mobile"
        defaultValue={searchQuery}
        className="block w-full rounded-md border-gray-300 py-3 px-4 placeholder-gray-500 shadow-sm focus:border-sky-500 focus:ring-sky-500"
        placeholder="Search"
        onChange={(e) => handleInputChange(e)}
        onClick={(e) => e.stopPropagation()}
      />
      {searchQuery && <button
        type="reset"
        className="absolute top-0 right-3 inline-flex justify-center rounded-md border border-transparent pt-3 pb-2"
        onClick={(e) => handleResetButton(e)}
      >
        <XMarkIcon className="block h-6 w-6 text-gray-500 hover:text-gray-900" aria-hidden="true" />
      </button>}
      <button
        type="submit"
        className="hidden"
      >
        Submit
      </button>
    </form>
	)
}